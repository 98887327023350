const getAddressNFTs = async (owner, retryAttempt = 0) => {
    if (retryAttempt === 5) {
        return;
    }
    if (owner) {
        let data;
        try {
            data = await fetch(`https://eth-mainnet.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_API_KEY}/getNFTsForOwner?owner=${owner}`).then(data => data.json())
        } catch (e) {
            getAddressNFTs(owner, retryAttempt+1)
        }

        return data;
    }
}

const fetchNFT = async (tokenId, address) => {
    try {
        const metadata = await fetch(`https://eth-mainnet.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_API_KEY}/getNFTMetadata?contractAddress=${address}&tokenId=${tokenId}`).then(data => data.json())
        return metadata
    } catch (e) {
        console.error(`[fetchNFT] [ERROR] No token with id: ${tokenId} ${e}`)
        return null
    }
}



const fetchNFTs = async (owner, setNFTs, setFilteredNFTs, hodl_addresses) => {
    let data = await getAddressNFTs(owner)
    if (data.ownedNfts.length) {
        // Filter NFT by hold addresses
        const filtered_NFTs = data.ownedNfts.filter(NFT => Object.values(hodl_addresses).includes(NFT.contract.address))
        setNFTs(filtered_NFTs)
        setFilteredNFTs(filtered_NFTs)
    } else {
        setNFTs(null)
    }
}

export { fetchNFTs, fetchNFT }