import React, { useState } from 'react';

import { getOpenSeaLink, getLooksRareLink, getX2Y2Link, getBlurLink } from '../../utils/exchangeLinks';
import { fetchVamp } from '../../utils/fetchVamp';
import { ImageWithLoading } from '../../utils/lookup_helpers';
import { contract_addresses } from '../../artifacts/contracts';
import '../../styles/Lookup.css';

const VampCheck = () => {

    const [vampiressImageLoaded, setVampiressImageLoaded] = useState(false)
    const [batImageLoaded, setBatImageLoaded] = useState(false)
    const [vampImageLoaded, setVampImageLoaded] = useState(false)

    const [vamp_id, setVampId] = useState(null)
    const [vampiress, setVampiress] = useState(null)
    const [bat, setBat] = useState(null)
    const [vamp, setVamp] = useState(null)
    const [, setFetchError] = useState(null)

    const checkVamp = (e) => {
        e.preventDefault()
        // console.log(e.target.value)
        if (vamp_id <= 0 || vamp_id > 8888) {
            document.getElementById('vampForm').getElementsByTagName('input')[0].setCustomValidity("Vamp ID must be between 1 and 8888")
            document.getElementById('vampForm').reportValidity()
            return
        } else if (vamp_id === undefined) {
            return
        }
        fetchVamp(vamp_id, setVampiress, setBat, setVamp, setFetchError)
        setVampiressImageLoaded(false)
        setBatImageLoaded(false)
        setVampImageLoaded(false)
    }

    const handleChange = e => {
        // console.log(e.target.value)
        setVampId(e.target.value)
    }

    return (
        <>
        <section id='VampLookup' className='lookup'>
        <div className='lookup-container'>
            <h2>Vampire Lookup</h2>
            <div className='lookup-header'>
                <form id='vampForm' onSubmit={e => checkVamp(e)}>
                    <div className='flex'>
                        <input id="vamp_id" type="number" minLength="1" min="1" max="12345" name="vamp_id" placeholder='Vampire ID' value={vamp_id || ''} onChange={e => handleChange(e)} required></input>
                        <button onSubmit={e => checkVamp(e)} aria-label="submit vamp id">
                            <svg viewBox="0 0 20 20" focusable="false" className="chakra-icon css-1cr58xf">
                                <path d="M3.38379 8.04736C3.38379 10.707 5.54199 12.8716 8.20166 12.8716C9.22998 12.8716 10.1758 12.5479 10.9565 11.9956L13.8511 14.8901C13.9971 15.0361 14.1938 15.106 14.397 15.106C14.8413 15.106 15.1523 14.7759 15.1523 14.3442C15.1523 14.1348 15.0762 13.9443 14.9429 13.8047L12.0674 10.9165C12.6704 10.1167 13.0259 9.12646 13.0259 8.04736C13.0259 5.3877 10.8677 3.22314 8.20166 3.22314C5.54199 3.22314 3.38379 5.3877 3.38379 8.04736ZM4.48828 8.04736C4.48828 5.99707 6.15137 4.33398 8.20166 4.33398C10.2583 4.33398 11.915 5.99707 11.915 8.04736C11.915 10.0977 10.2583 11.7607 8.20166 11.7607C6.15137 11.7607 4.48828 10.0977 4.48828 8.04736Z" fill="currentColor">
                                </path>
                            </svg>
                        </button>
                    </div>
                </form>
            </div>
            <div className='nfts'>
                {vamp ? 
                <div className='nft' title={`Vamp ${vamp.tokenId}`}>
                    <ImageWithLoading key={vamp.tokenId} styling={'bat'} src={vamp.image.cachedUrl} loaded={vampImageLoaded} setLoaded={setVampImageLoaded} alt={vamp.description} />
                    <div className='nft-footer'>
                        <h3>{vamp.tokenId}</h3>
                        <div className='links'>
                            {getOpenSeaLink(contract_addresses.svs,vamp.tokenId)}
                            {getLooksRareLink(contract_addresses.svs,vamp.tokenId)}
                            {getX2Y2Link(contract_addresses.svs,vamp.tokenId)}
                            {getBlurLink(contract_addresses.svs,vamp.tokenId)}
                        </div>
                    </div>
                </div> :
                <div className='nft'>
                    <img className='nft-img' key={0} src='images/coffin.gif' alt='provide input'></img>
                    <div className='nft-footer'>
                        <h3>🩸</h3>
                        <div className='links'></div>
                    </div>
                </div> }
                {bat ? 
                <div className='nft bat' title={`Vamp ${vamp.tokenId} has companion Bat ${bat.tokenId}`}>
                    {bat.name ?
                    <>
                        <ImageWithLoading key={bat.tokenId} styling={'bat'} src={bat.image.cachedUrl} loaded={batImageLoaded} setLoaded={setBatImageLoaded} alt={bat.description} />
                        <div className='nft-footer'>
                            <h3>🦇 {bat.tokenId}</h3>
                            <div className='links'>
                                {getOpenSeaLink(contract_addresses.sbs,bat.tokenId)}
                                {getLooksRareLink(contract_addresses.sbs,bat.tokenId)}
                                {getX2Y2Link(contract_addresses.sbs,bat.tokenId)}
                                {getBlurLink(contract_addresses.sbs,bat.tokenId)}
                            </div>
                        </div>
                    </>
                    : 
                    <>
                        <div className='image-container'>
                            <div className="img-container">
                                <img
                                    className={`nft-img bat`}
                                    src={`images/coffin.gif`}
                                    alt={'provide input'}
                                />
                            </div>
                            <p>No Companion</p>
                        </div>
                        <div className='nft-footer'>
                        <h3>🩸</h3>
                            <div className='links'></div>
                        </div>
                    </> 
                    }
                </div> : 
                <></> }
                {vampiress ? 
                <div className='nft bat' title={`Vamp ${vamp.tokenId} has perfect pair Vampiress ${vampiress.tokenId}`}>
                    <ImageWithLoading key={vampiress.tokenId} styling={'vampiress'} src={vampiress.image.cachedUrl} loaded={vampiressImageLoaded} setLoaded={setVampiressImageLoaded} alt={vampiress.description} />
                    <div className='nft-footer'>
                        <h3>❤️ {vampiress.tokenId}</h3>
                        <div className='links'>
                            {getOpenSeaLink(contract_addresses.svs2,vampiress.tokenId)}
                            {getLooksRareLink(contract_addresses.svs,vampiress.tokenId)}
                            {getX2Y2Link(contract_addresses.svs,vampiress.tokenId)}
                        </div>
                    </div>
                </div> : 
                <></> }
            </div>
        </div>
        </section>
        </>
    )

}

export default VampCheck;
