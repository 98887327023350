import React, { useState, useEffect } from 'react';
import fetchTestGameData from '../../artifacts/testGameData';
import InfoModal from './InfoModal';
import GameModal from './GameModal';
import JoinModal from './JoinModal';
import BalanceForm from './GameFormBalance';
import DepositForm from './GameFormDeposit';
import WithdrawForm from './GameFormWithdraw';
import '../../styles/Game.css';

const GamePage = ({ network, account, signMessage }) => {
    
    const [games, setGames] = useState(null)
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [filteredGames, setFilteredGames] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        let title = 'ImNotBot - Game'
        let description = 'Play the non official SVS coinflip game developed by ImNotBot.'
        let url = "https://lair.imnotbot.com/Game"
        document.title = title
        document.querySelector('meta[property="og:title"]').setAttribute('content', title)
        document.querySelector('meta[name="description"]').setAttribute('content', description)
        document.querySelector('meta[property="og:description"]').setAttribute('content', description)
        document.querySelector('meta[property="og:url"]').setAttribute('content', url)

        const setGameData = async () => {
            const fetchedGames = await fetchTestGameData()
            setGames(fetchedGames)
            setFilteredGames(fetchedGames)
            // fetchGameData(setGames)
        }
        setGameData()
    }, [])

    const filterGames = (filter) => {
        if (filter === 'all') {
            setFilteredGames(games)
            return
        }
        let filtered = []
        for (let i = 0; i < games.length; i++) {
            if (filter === 'open' && !games[i].players.two && !games[i].closed) {
                filtered.push(games[i])
            } else if (filter === 'finished' && games[i].flip && games[i].flip.end) {
                filtered.push(games[i])
            }
        }
        setFilteredGames(filtered)
    }

    const handleGameFilter = (e) => {
        let filter = null
        const buttons = document.getElementsByClassName('game-filters')[0].getElementsByTagName('button')
        for(let i = 0; i < buttons.length; i++) {
            if (buttons[i].dataset.value === e.target.dataset.value) {
                filter = buttons[i].dataset.value
                buttons[i].classList.add('active')
            } else {
                buttons[i].classList.remove('active')
            }
        }
        filterGames(filter)

    }

    const handleGameSort = (e) => {
        console.log(`Updating game sort to: ${e.target.value}`)
        const v = e.target.value
        if (v === 'high') {
            let sortedGames = filteredGames
            filteredGames.sort((a,b) => {
                return b.bet - a.bet
            })
            setFilteredGames(sortedGames)
        } else if (v === 'low') {
            let sortedGames = filteredGames
            filteredGames.sort((a,b) => {
                return a.bet - b.bet
            })
            setFilteredGames(sortedGames)
        } else if (v === 'recent') {
            let sortedGames = filteredGames
            filteredGames.sort((a,b) => {
                return b.created - a.created
            })
            setFilteredGames(sortedGames)
        } else if (v === 'oldest') {
            let sortedGames = filteredGames
            filteredGames.sort((a,b) => {
                return a.created - b.created
            })
            setFilteredGames(sortedGames)
        }
    }

    const handleInfoClick = () => {
        setShowInfoModal(true)
        document.body.style.overflow = 'hidden'
    }

    return (
    <>
    {
        showInfoModal ? <InfoModal showModal={showInfoModal} setShowModal={setShowInfoModal} /> : null
    }
    <section id='Game'>
        <div className='game-container'>
            <h2>
                Coin Flip
                <svg onClick={e => handleInfoClick(e)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#777'>
                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/>
                </svg>
            </h2>
            {network && network.name === 'matic' ? (
            <div className='games-header'>
                <GameTokenExchangeForm account={account} />
                <GameCreateForm games={games} setGames={setGames} />
            </div>) : 
            <div className='games-header-network-error'>
                <p>Change to <a href='https://wallet.polygon.technology/' target='_blank' rel={'noreferrer'}>MATIC</a> network to use utility</p>
                <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 192.146 192.146">
                    <path d="M108.186,144.372c0,7.054-4.729,12.32-12.037,12.32h-0.254c-7.054,0-11.92-5.266-11.92-12.32
                    c0-7.298,5.012-12.31,12.174-12.31C103.311,132.062,108.059,137.054,108.186,144.372z M88.44,125.301h15.447l2.951-61.298H85.46
				    L88.44,125.301z M190.372,177.034c-2.237,3.664-6.214,5.921-10.493,5.921H12.282c-4.426,0-8.51-2.384-10.698-6.233
                    c-2.159-3.849-2.11-8.549,0.147-12.349l84.111-149.22c2.208-3.722,6.204-5.96,10.522-5.96h0.332
                    c4.445,0.107,8.441,2.618,10.513,6.546l83.515,149.229C192.717,168.768,192.629,173.331,190.372,177.034z M179.879,170.634
                    L96.354,21.454L12.292,170.634H179.879z"/>
                </svg>
            </div>}
            <div className='game-filters'>
                <button data-value='all' className='active' onClick={e => handleGameFilter(e)}>All</button>
                <button data-value='open' onClick={e => handleGameFilter(e)}>Open</button>
                <button data-value='finished' onClick={e => handleGameFilter(e)}>Finished</button>
                <select data-value='sort' onChange={e => handleGameSort(e)}>
                    <option value='recent'>Recent</option>
                    <option value='oldest'>Oldest</option>
                    <option value='high'>High to Low</option>
                    <option value='low'>Low to High</option>
                </select>
            </div>
            <Games games={filteredGames} signMessage={signMessage} network={network}/>
        </div>
    </section>
    </>
    )

}

const GameTokenExchangeForm = ({ account }) => {

    const [tokenBalance, setTokenBalance] = useState(0)
    const [depositAmount, setDepositAmount] = useState(0)
    const [withdrawAmount, setWithdrawAmount] = useState(0)

    return (
    <div className='token-exchange'>
        <BalanceForm tokenBalance={tokenBalance} setTokenBalance={setTokenBalance} account={account} />
        <DepositForm depositAmount={depositAmount} setDepositAmount={setDepositAmount} />
        <WithdrawForm withdrawAmount={withdrawAmount} setWithdrawAmount={setWithdrawAmount} />
    </div>
    )
}

const GameCreateForm = ({ games, setGames }) => {

    const [betAmount, setBetAmount] = useState(1)
    const [betChoice, setBetChoice] = useState(undefined)

    const handleChoice = (e) => {
        e.preventDefault()
        const buttons = document.getElementsByClassName('create-game')[0].getElementsByClassName('buttons')[0].getElementsByTagName('button')
        for(let i = 0; i < buttons.length; i++) {
            if (buttons[i].dataset.value === e.target.dataset.value) {
                setBetChoice(buttons[i].dataset.value)
                buttons[i].classList.add('active')
            } else {
                buttons[i].classList.remove('active')
            }
        }
    }

    const handleCreateGame = (e) => {
        e.preventDefault()
        if (!betChoice) {
            console.log(`[ERROR] [handleCreateGame]: betChoice = ${betChoice}`)
            document.getElementById('hostForm').getElementsByClassName('buttons')[0].getElementsByTagName('button')[0].setCustomValidity("Choose a moon side")
            document.getElementById('hostForm').reportValidity()
            return
        } else if (!betAmount || betAmount <= 0) {
            console.log(`[ERROR] [handleCreateGame]: betAmount = ${betAmount}`)
            document.getElementById('bet-input').setCustomValidity("Bet amount must be greater than 0")
            document.getElementById('hostForm').reportValidity()
            return
        }
        // console.log('create game')
        var created = Date.now()
        const game = {
            "id": games.length+1,
            "created": created,
            "bet": document.getElementById('bet-input').value,
            "players": {
                "one": {
                    "choice": betChoice,
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1"
                }
            },
            "result": null,
            "finished": null
        }
        // console.log(game)
        // TODO: fetch games on chain also
        games.push(game)
        setGames(games)
        document.getElementsByClassName('game-filters')[0].getElementsByTagName('button')[0].click()
    }

    const handleBet = (e) => {
        const value = e.charCode >= 48 && e.charCode <= 57
        console.log(value)
        if (!value) {
            setBetAmount(e.target.value)
        }
        
    }

    return (
    <div className='create-game'>
        <form id='hostForm'>
            <div className='buttons'>
                <button title='Blood Moon' data-value='heads' onClick={e => handleChoice(e)} className='blood-moon'>BLOOD MOON</button>
                <button title='Full Moon' data-value='tails' onClick={e => handleChoice(e)} className='moon'>FULL MOON</button>
            </div>
            <div className='inputs'>
                <div className='game-input'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                        <path d="M5.008 1.29836C4.54715 2.46516 3.87888 3.63053 2.81821 5.15172C1.6435 6.83653 0.839539 8.31929 0.631056 9.10054L5.008 1.29836ZM5.008 1.29836C5.45327 2.36652 6.13403 3.58054 6.9741 4.81412L6.97411 4.81413C9.0977 7.93225 9.50212 8.90088 9.49999 10.2443C9.4981 11.4501 9.10417 12.3599 8.20703 13.2121L8.20702 13.2121C6.39935 14.9293 3.60065 14.9293 1.79298 13.2121L1.79296 13.2121C0.895828 12.3599 0.501901 11.4501 0.500004 10.2443L0.500004 10.2443C0.49939 9.88547 0.562614 9.35741 0.631025 9.10066L5.008 1.29836Z" stroke="#5C23D1"/>
                    </svg>
                    <input id='bet-input' type='number' min='1' placeholder='PBLOOD' value={betAmount} onChange={e => handleBet(e) } required></input>
                </div>
                <button id='create-game' onClick={e => handleCreateGame(e)}>Host Game</button>
            </div>
        </form>
    </div>
    )
}

const Games = ({ games, signMessage, network }) => {

    const [modalGame, setModalGame] = useState(null)
    const [showGameModal, setShowGameModal] = useState(null)
    const [modalJoin, setModalJoin] = useState(null)
    const [showJoinModal, setShowJoinModal] = useState(null)

    return (
        <>
        {
            modalGame && showGameModal ? <GameModal game={modalGame} showModal={showGameModal} setShowModal={setShowGameModal} /> : null
        }
        {
            modalJoin && showJoinModal ? <JoinModal game={modalJoin} showModal={showJoinModal} setShowModal={setShowJoinModal} /> : null
        }
        <div className='games'>
        {
            games ? games.map(game => {
              return (
                <Game 
                key={game.id} 
                game={game}
                setModalGame={setModalGame}
                setShowGameModal={setShowGameModal}
                setModalJoin={setModalJoin}
                setShowJoinModal={setShowJoinModal}
                signMessage={signMessage}
                network={network}></Game>
              )
            }) : <div>No Games running</div>
        }
        </div>
        </>
    )
}

const Game = ({ game, setModalGame, setShowGameModal, setModalJoin, setShowJoinModal, signMessage, network }) => {

    const handleJoinClick = () => {
        console.log('join')
        signMessage()
    }

    const handleJoinClickError = () => {
        console.log('join on wrong network')
        setModalJoin(game)
        setShowJoinModal(true)
        document.body.style.overflow = 'hidden'
    }

    const makeMoon = (choice) => {
        if (choice === 'heads') {
            return <span className='blood-moon'></span>
        } else {
            return <span className='moon'></span>
        }
    }

    const handleGameInfoClick = (e) => {
        console.log(e.target.dataset.id)
        setModalGame(game)
        setShowGameModal(true)
        document.body.style.overflow = 'hidden'
    }

    return (
        <>
        <div className={`game${game.closed ? ' closed' : ''}`} data-id={game.id}>
            <div className='game-header'>
                <h3 className='game-id'>{game.id}</h3>
                <svg onClick={e => handleGameInfoClick(e)} data-id={game.id} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#5C23D1'><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/></svg>
            </div>
            <div className='player-container'>
                <div className={game.flip && game.flip.result ? (game.flip.result === game.players.one.choice ? 'player winner' : 'player loser') : 'player'}>
                    <img src='https://ipfs.io/ipfs/bafybeibu3edpaeds5w2a23m6cnwlaakvqlvz3ywx4pl2m3i4iigynqdvuy/4897.png' alt={'player one'}></img>
                    <div className='player-data'>
                        {makeMoon(game.players.one.choice)}
                    </div>
                </div>
                {
                game.closed ? (
                    <div className='player closed'>
                        <img src='images/coffin.gif' alt={'empty player two'}></img>
                        <div className='player-data'>
                            <button  onClick={e => handleGameInfoClick(e)}>Closed</button>
                        </div>
                    </div>
                ) : (
                    <>
                    {
                    game.players.two ? (
                    <div className={game.flip && game.flip.result ? (game.flip.result === game.players.two.choice ? 'player winner' : 'player loser') : 'player'}>
                        <img src='https://ipfs.io/ipfs/bafybeibu3edpaeds5w2a23m6cnwlaakvqlvz3ywx4pl2m3i4iigynqdvuy/4981.png' alt={'player two'}></img>
                        <div className='player-data'>
                            {makeMoon(game.players.two.choice)}
                        </div>
                    </div>
                    ) : (
                    <div className='player empty'>
                        <img src='images/coffin.gif' alt={'empty player two'}></img>
                        <div className='player-data'>
                            <button onClick={network && network.name === 'matic' ? (e => handleJoinClick(e)) : (e => handleJoinClickError(e))} data-game={game.id}>Join</button>
                        </div>
                    </div>
                    )
                    }
                    </>
                )
            }
                
            </div>
            <div className='bet'>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path d="M5.008 1.29836C4.54715 2.46516 3.87888 3.63053 2.81821 5.15172C1.6435 6.83653 0.839539 8.31929 0.631056 9.10054L5.008 1.29836ZM5.008 1.29836C5.45327 2.36652 6.13403 3.58054 6.9741 4.81412L6.97411 4.81413C9.0977 7.93225 9.50212 8.90088 9.49999 10.2443C9.4981 11.4501 9.10417 12.3599 8.20703 13.2121L8.20702 13.2121C6.39935 14.9293 3.60065 14.9293 1.79298 13.2121L1.79296 13.2121C0.895828 12.3599 0.501901 11.4501 0.500004 10.2443L0.500004 10.2443C0.49939 9.88547 0.562614 9.35741 0.631025 9.10066L5.008 1.29836Z" stroke="#5C23D1"/>
                </svg>
                <h4>{game.bet}</h4>
            </div>
        </div>
        </>
    )

}

export default GamePage;