import {Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Web3Modal from "web3modal";
import { ethers } from "ethers";

import '../styles/App.css';
import Nav from './Nav';
import Hodls from './hodls/Hodls';
import Data from './data/Data';
import VampsPage from './vamps/VampsPage';
import GamePage from './game/GamePage';
import Error404Page from './404Page.js';
import Footer from './Footer';
import { providerOptions } from "./providerOptions";

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark"
});

function App() {

  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();

  const connectWallet = async () => {
    // console.log('connect')
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      setNetwork(network);
      if (accounts) setAccount(accounts[0])
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account]
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature]
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = async () => {
    web3Modal.clearCachedProvider();
    refreshState();
  };

  useEffect(() => {
    // connect automatically and without a popup if user is already connected
    if(web3Modal && web3Modal.cachedProvider){
      connectWallet()
    }
  }, [])

  useEffect(() => {
    if (provider?.on) {
      // console.log(provider)

      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        // console.log("chain changed")
        // console.log(_hexChainId)
        // console.log(provider)
        setChainId(_hexChainId);
        connectWallet()
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider])

  return (
    <>
      <Nav account={account} connectWallet={connectWallet} disconnect={disconnect} network={network} />
      <Routes>
          <Route path='/' element={<Hodls />} />
          <Route path='/hodls' element={<Hodls />} />
          <Route path='/data' element={<Data />} />
          <Route path='/vamps' element={<VampsPage />} />
          <Route path='/games' element={<GamePage network={network} account={account} signMessage={signMessage} />} />
          <Route path='*' element={<Error404Page />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
