import React from 'react';

import '../styles/404.css';

const Error404Page = () => {

  document.title = 'ImNotBot - 404'

  return (
    <main>
      <section id='Error404'>
        <article className='info-article'>
          <div className='text-container'>
            <h2>404</h2>
            <p>This page could not be found</p>
          </div>
        </article>
      </section>
    </main>
  );
}

export default Error404Page;
