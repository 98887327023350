const fetchTestGameData = async () => {
    const fetchedGames = [
        {
            "id": 1,
            "created": "1664788817",
            "bet": 100,
            "players": {
                "one": {
                    "choice": "heads",
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1"
                },
                "two": {
                    "choice": "tails",
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1",
                    "joined": "1664788937"
                }
            },
            "flip": {
                "status": "final",
                "start": "1664788997",
                "end": "1664788997",
                "result": "tails"
            },
            "closed": null
        },
        {
            "id": 2,
            "created": "1664875348",
            "bet": 420,
            "players": {
                "one": {
                    "choice": "tails",
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1"
                }
            },
            "flip": null,
            "closed": null
        },
        {
            "id": 3,
            "created": "1664961748",
            "bet": 10,
            "players": {
                "one": {
                    "choice": "tails",
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1"
                },
                "two": {
                    "choice": "heads",
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1",
                    "joined": "1664962288"
                }
            },
            "flip": {
                "status": "final",
                "start": "1664962294",
                "end": "1664962358",
                "result": "tails"
            }
        },
        {
            "id": 4,
            "created": "1664966078",
            "bet": 1,
            "players": {
                "one": {
                    "choice": "heads",
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1"
                }
            },
            "flip": null,
            "closed": "1664976878"
        },
        {
            "id": 5,
            "created": "1664966078",
            "bet": 1069,
            "players": {
                "one": {
                    "choice": "heads",
                    "address": "0x0029b3D3f83896A20ecDC141052B4f8D19C867c1"
                }
            },
            "flip": null,
            "closed": null
        }
    ]
    return fetchedGames
}

export default fetchTestGameData