const DepositForm = ({ depositAmount, setDepositAmount }) => {

    const handleDeposit = (e) => {
        e.preventDefault()
        console.log(depositAmount)
        if (!depositAmount || depositAmount <= 0) {
            document.getElementById('depositForm').getElementsByTagName('input')[0].setCustomValidity("deposit amount must be greater than 0")
            document.getElementById('depositForm').reportValidity()
            return
        }
        console.log({
            "depositAmount": depositAmount
        })
    }

    return (
        <form id='depositForm'>
            <div className='game-input'>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path d="M5.008 1.29836C4.54715 2.46516 3.87888 3.63053 2.81821 5.15172C1.6435 6.83653 0.839539 8.31929 0.631056 9.10054L5.008 1.29836ZM5.008 1.29836C5.45327 2.36652 6.13403 3.58054 6.9741 4.81412L6.97411 4.81413C9.0977 7.93225 9.50212 8.90088 9.49999 10.2443C9.4981 11.4501 9.10417 12.3599 8.20703 13.2121L8.20702 13.2121C6.39935 14.9293 3.60065 14.9293 1.79298 13.2121L1.79296 13.2121C0.895828 12.3599 0.501901 11.4501 0.500004 10.2443L0.500004 10.2443C0.49939 9.88547 0.562614 9.35741 0.631025 9.10066L5.008 1.29836Z" stroke="#5C23D1"/>
                </svg>
                <input id='deposit-input' type='number' min='1' placeholder='PBLOOD' value={depositAmount} onChange={e => setDepositAmount(e.target.value) } required></input>
            </div>
            <button id='deposit-tokens' onClick={e => handleDeposit(e)}>Deposit</button>
        </form>
    )

}

export default DepositForm