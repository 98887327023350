import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { fetchEthGas } from '../utils/fetchEthGas';
import '../styles/Nav.css';
import { truncateAddress } from '../utils/addressUtils';

const Nav = ({ account, connectWallet, disconnect, network }) => {

    const [showBurgerMenu, setShowBurgerMenu] = useState(false)
    const [ethGas, setEthGas] = useState(null)

    useEffect(() => {
        fetchEthGas(setEthGas)
        const gasInterval = setInterval(() => {
            fetchEthGas(setEthGas)
          }, 20000)
          return () => clearInterval(gasInterval)
    }, [])

    const handleBurgerMenu = () => {
        setShowBurgerMenu(!showBurgerMenu)
        if (showBurgerMenu) {
            document.getElementById('burger').classList.add('active')
            document.getElementById('burger-menu').classList.add('active')
        } else {
            document.getElementById('burger').classList.remove('active')
            document.getElementById('burger-menu').classList.remove('active')
        }
    }

    return (
    <>
        <nav>
            <h1><Link to='/'>ImNotBot</Link></h1>
            <div className='nav-links'>
                <Link to='/Hodls'>Hodls</Link>
                <Link to='/Data'>Data</Link>
                <Link to='/Vamps'>Vamps</Link>
                <Link to='/Games'>Games</Link>
                {!account ? (
                    <button className='walletBtn' onClick={connectWallet}>Connect Wallet</button>
                ) : (
                    <button className='walletBtn' onClick={disconnect}>{truncateAddress(account)}<b>&#x2715;</b></button>
                )}
            </div>
        </nav>
        <div id='burger' onClick={(e) => handleBurgerMenu(e)}>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div id='burger-menu'>
            <div className='links'>
                <Link to='/Hodls' onClick={(e) => handleBurgerMenu(e)} >Hodls</Link>
                <Link to='/Data' onClick={(e) => handleBurgerMenu(e)} >Data</Link>
                <Link to='/Vamps' onClick={(e) => handleBurgerMenu(e)} >Vamps</Link>
                <Link to='/Games' onClick={(e) => handleBurgerMenu(e)} >Games</Link>
                {!account ? (
                    <button className='walletBtn' onClick={connectWallet}>Connect Wallet</button>
                ) : (
                    <button className='walletBtn' onClick={disconnect}>{truncateAddress(account)}<b>&#x2715;</b></button>
                )}
            </div>
        </div>
        <div id='banners'>
            {ethGas ? (
                <div id="gas">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gas-pump" className="svg-inline--fa fa-gas-pump fa-w-16 mr-1 small" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"></path>
                    </svg>
                    <span>{ethGas}</span>
                </div> 
            ) : (
            <></>  
            )}
            <div id='walletStatus'>
                <span id='statusIndicator' className={!account ? 'disconnected' : 'connected' }></span>
                <p>{!account ? '' : `${network ? network.name.toUpperCase() : ''}` }</p>
            </div>
        </div>
    </>
    )
};

// const WalletStatus = () => {
//     const metmask = () => {
        
//     }
//     const coinbase = () => {
//         return (
//         <svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" id="Layer_1" x="0px" y="0px" viewBox="0 0 1024 1024" overflow="visible">
//             <path fill="#0052FF" d="M512,0L512,0c282.8,0,512,229.2,512,512l0,0c0,282.8-229.2,512-512,512l0,0C229.2,1024,0,794.8,0,512l0,0  C0,229.2,229.2,0,512,0z"/>
//             <path fill="#FFFFFF" d="M512.1,692c-99.4,0-180-80.5-180-180s80.6-180,180-180c89.1,0,163.1,65,177.3,150h181.3  c-15.3-184.8-170-330-358.7-330c-198.8,0-360,161.2-360,360s161.2,360,360,360c188.7,0,343.4-145.2,358.7-330H689.3  C675,627,601.2,692,512.1,692z"/>
//         </svg>
//         )
//     }
// }

export default Nav;