import React, { useState, useEffect } from 'react';

import '../../styles/Lair.css';

function formatDatetime(date) {
  var dateObj = new Date(date); 
  var datetime = dateObj.getDate() + " "
      + (dateObj.toLocaleString('default', { month: 'long' }))  + ", " 
      + dateObj.getFullYear() 
  
  return datetime
}

const Data = () => {

  const [data, setData] = useState(null)

  const getLairData = async () => {
    let fetchData = await fetch('https://data.imnotbot.com/lair.json')
  const result = await fetchData.json();
    setData(result)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    let title = 'ImNotBot - Data'
    let description = 'Browse an overview of curated data to get an insight into the Sneaky Vampire Syndicate (SVS) collections.'
    let url = "https://lair.imnotbot.com/Data"
    document.title = title
    document.querySelector('meta[property="og:title"]').setAttribute('content', title)
    document.querySelector('meta[name="description"]').setAttribute('content', description)
    document.querySelector('meta[property="og:description"]').setAttribute('content', description)
    document.querySelector('meta[property="og:url"]').setAttribute('content', url)
    getLairData()
  }, [])


  return (
    <>
    <section id='Lair'>
      <div className='lair-container'>
        <h2>Lair</h2>
        <div className='articles'>
          { data ? <>
            <SVS contract={data.lair.contracts.svs} svsg_contract={data.lair.contracts.svsg} />
            <SBS contract={data.lair.contracts.sbs} svsg_contract={data.lair.contracts.svsg} />
            <SVS2 contract={data.lair.contracts.svs2} />
            <CHAMP contract={data.lair.contracts.champ} />
            </>
          : <></>
          }
            {/* <PBLOOD contract={contract} /> */}
        </div>
      </div>
    </section>
    </>
  )

}

const truncateString = (v) => {
  return <>
    {v.slice(0, 5)}&#8230;{v.slice(v.length - 5)}
  </>
}

const SVS = ({contract, svsg_contract}) => {

  let top_ten = []
  for (let i = 0; i < contract.top_ten.length; i++) {
    top_ten.push(
    <tr key={"svs-"+i}>
      <td>{i+1}</td>
      <td><a href={`https://etherscan.io/address/${contract.top_ten[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(contract.top_ten[i].wallet)}</a></td>
      <td>{contract.top_ten[i].count}</td>
      <td>{(contract.top_ten[i].count / contract.total_supply * 100).toFixed(2)}%</td>
    </tr>)
  }

  let top_ten_buried = []
  for (let i = 0; i < svsg_contract.top_ten_vamp.length; i++) {
    top_ten_buried.push(
    <tr key={"svs-2-"+i}>
      <td>{i+1}</td>
      <td><a href={`https://etherscan.io/address/${svsg_contract.top_ten_vamp[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(svsg_contract.top_ten_vamp[i].wallet)}</a></td>
      <td>{svsg_contract.top_ten_vamp[i].count}</td>
      <td>{(svsg_contract.top_ten_vamp[i].count / contract.total_supply * 100).toFixed(2)}%</td>
    </tr>)
  }

  return (
    <article id="svs">
      <h3><img src='images/svs-logo.avif' alt={'sneaky vampire syndicate logo'}></img>SVS <a href={'https://etherscan.io/address/'+contract.address} target='_blank' rel="noreferrer"><img src='images/etherscan-logo.svg' alt={'etherscan logo'}></img></a></h3>
      <div className="data-container">
        <div className="data">
          <span className="title">Tokens</span>
          <span className="value">{contract.total_supply.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Holders</span>
          <span className="value">{contract.holders.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Buried</span>
          <span className="value">{contract.buried.toLocaleString("en-US")} ... {Math.ceil(contract.buried / contract.total_supply * 100)}%</span>
        </div>
        <div className="data">
          <span className="title">Actual Holders</span>
          <span className="value">{contract.holders_actual.toLocaleString("en-US")}</span>
        </div>
      </div>
      <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10 Holders</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10 Buried</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten_buried}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='data-footer'>
        <span>Last Update: {formatDatetime(contract.updated)}</span>
      </div>
    </article>
  )
}

const SBS = ({contract, svsg_contract}) => {

  let top_ten = []
  for (let i = 0; i < contract.top_ten.length; i++) {
    top_ten.push(
    <tr key={"sbs-"+i}>
      <td>{i+1}</td>
      <td><a href={`https://etherscan.io/address/${contract.top_ten[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(contract.top_ten[i].wallet)}</a></td>
      <td>{contract.top_ten[i].count}</td>
      <td>{(contract.top_ten[i].count / contract.total_supply * 100).toFixed(2)}%</td>
    </tr>)
  }

  let top_ten_buried = []
  for (let i = 0; i < svsg_contract.top_ten_bat.length; i++) {
    top_ten_buried.push(
    <tr key={"sbs-2-"+i}>
      <td>{i+1}</td>
      <td><a href={`https://etherscan.io/address/${svsg_contract.top_ten_bat[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(svsg_contract.top_ten_bat[i].wallet)}</a></td>
      <td>{svsg_contract.top_ten_bat[i].count}</td>
      <td>{(svsg_contract.top_ten_bat[i].count / contract.total_supply * 100).toFixed(2)}%</td>
    </tr>)
  }
  
  return (
    <article id="sbs">
      <h3><img src='images/sbs-logo.avif' alt={'sneaky bat syndicate logo'}></img>SBS <a href={'https://etherscan.io/address/'+contract.address} target='_blank' rel="noreferrer"><img src='images/etherscan-logo.svg' alt={'etherscan logo'}></img></a></h3>
      <div className="data-container">
        <div className="data">
          <span className="title">Tokens</span>
          <span className="value">{contract.total_supply.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Holders</span>
          <span className="value">{contract.holders.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Buried</span>
          <span className="value">{contract.buried.toLocaleString("en-US")} ... {(contract.buried / contract.total_supply * 100).toFixed(2)}%</span>
        </div>
        <div className="data">
          <span className="title">Actual Holders</span>
          <span className="value">{contract.holders_actual.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Devoured</span>
          <span className="value">{contract.devoured.toLocaleString("en-US")} ... {(contract.devoured / contract.total_supply * 100).toFixed(2)}%</span>
        </div>
      </div>
      <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10 Holders</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10 Buried</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten_buried}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='data-footer'>
        <span>Last Update: {formatDatetime(contract.updated)}</span>
      </div>
    </article>
  )
}

const SVS2 = ({contract}) => {

  let top_ten = []
  for (let i = 0; i < contract.top_ten.length; i++) {
    top_ten.push(
    <tr key={"svs2-"+i}>
      <td>{i+1}</td>
      <td><a href={`https://etherscan.io/address/${contract.top_ten[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(contract.top_ten[i].wallet)}</a></td>
      <td>{contract.top_ten[i].count}</td>
      <td>{(contract.top_ten[i].count / contract.total_supply * 100).toFixed(2)}%</td>
    </tr>)
  }

  let top_ten_generating_blood = []
  for (let i = 0; i < contract.top_ten_generating_blood.length; i++) {
    top_ten_generating_blood.push(
    <tr key={"svs2-"+i}>
      <td>{i+1}</td>
      <td><a href={`https://etherscan.io/address/${contract.top_ten_generating_blood[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(contract.top_ten_generating_blood[i].wallet)}</a></td>
      <td>{contract.top_ten_generating_blood[i].count}</td>
      <td>{(contract.top_ten_generating_blood[i].count / contract.total_supply * 100).toFixed(2)}%</td>
    </tr>)
  }

  return (
    <article id="svs2">
      <h3><img src='images/svs2-logo.avif' alt={'sneaky vampiress syndicate logo'}></img>SVS2 <a href={'https://etherscan.io/address/'+contract.address} target='_blank' rel="noreferrer"><img src='images/etherscan-logo.svg' alt={'etherscan logo'}></img></a></h3>
      <div className="data-container">
        <div className="data">
          <span className="title">Tokens</span>
          <span className="value">{contract.total_supply.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Holders</span>
          <span className="value">{contract.holders.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Generating Blood</span>
          <span className="value">{contract.generating_blood.toLocaleString("en-US")} ... {(contract.generating_blood / contract.total_supply * 100).toFixed(2)}%</span>
        </div>
      </div>
      <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10 Generating Blood</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten_generating_blood}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='data-footer'>
        <span>Last Update: {formatDatetime(contract.updated)}</span>
      </div>
    </article>
  )
}

// const PBLOOD = ({contract}) => {

//   let top_ten = []
//   for (let i = 0; i < contract.top_ten.length; i++) {
//     top_ten.push(
//     <tr>
//       <td>{i+1}</td>
//       <td><a href={`https://etherscan.io/address/${contract.top_ten[i].wallet}`} target="_blank" rel="noreferrer">{contract.top_ten[i].wallet}</a></td>
//       <td>{contract.top_ten[i].count}</td>
//       <td>{(contract.top_ten[i].count / contract.total_supply * 100).toFixed(2)}%</td>
//     </tr>)
//   }

//   return (
//     <article id="pblood">
//       <h3>PBLOOD <a href={'https://etherscan.io/address/'+contract.address} target='_blank'><img src='images/etherscan-logo.svg' alt={'etherscan logo'}></img></a></h3>
//       <div className="data-container">
//         <div className="data">
//           <span className="title">Tokens</span>
//           <span className="value">{contract.total_supply.toLocaleString("en-US")}</span>
//         </div>
//         <div className="data">
//           <span className="title">Holders</span>
//           <span className="value">{contract.holders.toLocaleString("en-US")}</span>
//         </div>
//         <div className="data">
//           <span className="title">Volume</span>
//           <span className="value">{contract.volume.toLocaleString("en-US")}</span>
//         </div>
//       </div>
//       <div className="data-container">
//         <div className="data-table">
//           <h4 className="table-header">Top 10 Holders</h4>
//           <div className="table-container">
//             <table>
//               <tr>
//                 <th>#</th>
//                 <th>Wallet</th>
//                 <th>Qty</th>
//                 <th>%</th>
//               </tr>
//               {top_ten}
//             </table>
//           </div>
//         </div>
//       </div>
//     </article>
//   )
// }

const CHAMP = ({contract}) => {

  let top_ten = []
  for (let i = 0; i < contract.top_ten.length; i++) {
    top_ten.push(
    <tr key={"champ-"+i}>
      <td>{i+1}</td>
      <td><a href={`https://etherscan.io/address/${contract.top_ten[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(contract.top_ten[i].wallet)}</a></td>
      <td>{contract.top_ten[i].count}</td>
      <td>{(contract.top_ten[i].count / contract.total_supply * 100).toFixed(2)}%</td>
    </tr>)
  }

  // let top_ten_burned = []
  // for (let i = 0; i < contract.top_ten_burned.length; i++) {
  //   top_ten_burned.push(
  //   <tr key={"champ-2-"+i}>
  //     <td>{i+1}</td>
  //     <td><a href={`https://etherscan.io/address/${contract.top_ten_burned[i].wallet}`} target="_blank" rel="noreferrer">{truncateString(contract.top_ten_burned[i].wallet)}</a></td>
  //     <td>{contract.top_ten_burned[i].count}</td>
  //     <td>{(contract.top_ten_burned[i].count / contract.total_supply * 100).toFixed(2)}%</td>
  //   </tr>)
  // }

  return (
    <article id="champ">
      <h3><img src='images/champ-logo.avif' alt={'svs champagne logo'}></img>CHAMP <a href={'https://etherscan.io/address/'+contract.address} target='_blank' rel="noreferrer"><img src='images/etherscan-logo.svg' alt={'etherscan logo'}></img></a></h3>
      <div className="data-container">
        <div className="data">
          <span className="title">Tokens</span>
          <span className="value">{contract.total_supply.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Holders</span>
          <span className="value">{contract.holders.toLocaleString("en-US")}</span>
        </div>
        <div className="data">
          <span className="title">Burned</span>
          <span className="value">{contract.burned.toLocaleString("en-US")} ... {(contract.burned / contract.total_supply * 100).toFixed(2)}%</span>
        </div>
      </div>
      <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10 Holders</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="data-container">
        <div className="data-table">
          <h4 className="table-header">Top 10 Burned Holders</h4>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Qty</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                {top_ten_burned}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <div className='data-footer'>
        <span>Last Update: {formatDatetime(contract.updated)}</span>
      </div>
    </article>
  )
}

export default Data;
