import { truncateAddress } from '../../utils/addressUtils';
import '../../styles/GameModal.css';

const GameModal = ({ game, showModal, setShowModal }) => {

    const makeMoon = (choice) => {
        if (choice === 'heads') {
            return <span className='blood-moon'></span>
        } else {
            return <span className='moon'></span>
        }
    }

    function hideModal() {
        setShowModal(false)
        document.body.style.overflow = 'auto'
    }

    const formatDate = (unix_time) => {
        const date = new Date(unix_time * 1000)
        const date_str = `${date.toLocaleDateString("en-US")} ${date.toLocaleTimeString("en-US")}`
        return date_str
    }

    return (
        <div id="modal" style={showModal ? {visibility: "visible"} : {visibility: "none"}} onClick={hideModal}>
            <div className='game-modal'>
                <div className='game-header'>
                    <h3 className='game-id'>Game {game.id}</h3>
                    <div id='close-game-modal' onClick={hideModal}>&#x2715;</div>
                </div>
                <div className='game-timeline'>
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Action</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{truncateAddress(game.players.one.address)}</td>
                                <td><div className='container'>Created {makeMoon(game.players.one.choice)}</div></td>
                                <td>{formatDate(game.created)}</td>
                            </tr>
                            { game.players.two && game.players.two.joined ? (
                                <tr>
                                    <td>{truncateAddress(game.players.two.address)}</td>
                                    <td><div className='container'>Joined {makeMoon(game.players.two.choice)}</div></td>
                                    <td>{formatDate(game.players.two.joined)}</td>
                                </tr>
                            ) : null }
                            { game.closed ? (
                                <tr>
                                    <td>{truncateAddress(game.players.one.address)}</td>
                                    <td>Closed</td>
                                    <td>{formatDate(game.closed)}</td>
                                </tr>
                            ) : null }
                            { game.flip ? (
                                <tr>
                                    <td></td>
                                    <td><div className='container'>Flip {makeMoon(game.flip.result)}</div></td>
                                    <td>{formatDate(game.flip.end)}</td>
                                </tr>
                            ) : null }
                        </tbody>
                    </table>
                </div>
                <div className='bet'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
                        <path d="M5.008 1.29836C4.54715 2.46516 3.87888 3.63053 2.81821 5.15172C1.6435 6.83653 0.839539 8.31929 0.631056 9.10054L5.008 1.29836ZM5.008 1.29836C5.45327 2.36652 6.13403 3.58054 6.9741 4.81412L6.97411 4.81413C9.0977 7.93225 9.50212 8.90088 9.49999 10.2443C9.4981 11.4501 9.10417 12.3599 8.20703 13.2121L8.20702 13.2121C6.39935 14.9293 3.60065 14.9293 1.79298 13.2121L1.79296 13.2121C0.895828 12.3599 0.501901 11.4501 0.500004 10.2443L0.500004 10.2443C0.49939 9.88547 0.562614 9.35741 0.631025 9.10066L5.008 1.29836Z" stroke="#5C23D1"/>
                    </svg>
                    <h4>{game.bet}</h4>
                </div>
            </div>
        </div>
    )
}

export default GameModal