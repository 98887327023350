import { getOpenSeaLink, getLooksRareLink, getX2Y2Link, getBlurLink } from '../../utils/exchangeLinks';

const NftModal = ({ NFT, showModal, setShowModal }) => {

    function hideModal() {
        setShowModal(false)
        document.body.style.overflow = 'auto'
    }

    return (
        <div id="modal" style={showModal ? {visibility: "visible"} : {visibility: "none"}} onClick={hideModal}>
            {/* <div id='close-modal' onClick={hideModal}>&#x2715;</div> */}
            <div className='nft-modal'>
                <img className='nft-img' src={NFT.image.cachedUrl} alt={NFT.description}></img>
                <div className='nft-metadata'>
                    <div className="nft-stats">
                        <h3 className="nft-title">{NFT.name}</h3>
                    </div>
                    <div className="nft-attributes">
                        {NFT.raw.metadata.attributes?.length > 0 && NFT.raw.metadata.attributes.map(attribute => {
                            return (
                                <div key={attribute.trait_type+attribute.id} className="nft-attribute">
                                    <span className="nft-attribute-type">{attribute.trait_type}</span>
                                    <span className="nft-attribute-value">{attribute.value}</span>
                                </div>
                            )
                        })}
                    </div>
                    <div className='links'>
                        {/* {console.log(NFT)} */}
                        {getOpenSeaLink(NFT.contract.address,parseInt(NFT.tokenId))}
                        {getLooksRareLink(NFT.contract.address,parseInt(NFT.tokenId))}
                        {getX2Y2Link(NFT.contract.address,parseInt(NFT.tokenId))}
                        {getBlurLink(NFT.contract.address,parseInt(NFT.tokenId))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NftModal