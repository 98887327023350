const ImageWithLoading = ({ src, styling, loaded, setLoaded, alt, }) => {

    return (
    <>
        {!loaded ? (
        <div className='image-container'>
            <div className="img-container">
                <img
                    className={`nft-img ${styling}`}
                    src={`images/${styling}-pre.gif`}
                    alt={'provide input'}
                />
            </div>
            <p>Loading IPFS</p>
        </div>
        ) : null}
        <div className="img-container">
            <img
                className={`nft-img ${styling}`}
                src={src}
                style={!loaded ? { display: 'none' } : {}}
                onLoad={() => setLoaded(true)}
                onError={() => {
                    console.log(`[FetchImage] [ERROR] Unable to fetch image ${src}`)
                    // setImageError(true)
                }}
                alt={alt}
            />
        </div>
    </>
    )
}

export { ImageWithLoading };