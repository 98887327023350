import React from 'react';

import VampiressCheck from './VampiressCheck';
import VampCheck from './VampCheck';
// import DevourSale from './DevourSale';

const VampsPage = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0)
        let title = 'ImNotBot - Vamp Lookup'
        let description = 'Lookup Sneaky Vampire Syndicate tokens and the connections they have between all of the SVS collections.'
        let url = "https://lair.imnotbot.com/Vamps"
        document.title = title
        document.querySelector('meta[property="og:title"]').setAttribute('content', title)
        document.querySelector('meta[name="description"]').setAttribute('content', description)
        document.querySelector('meta[property="og:description"]').setAttribute('content', description)
        document.querySelector('meta[property="og:url"]').setAttribute('content', url)
    }, [])

    return (
        <>
            <VampCheck />
            <VampiressCheck />
            {/* <DevourSale /> */}
        </>
    )

}

export default VampsPage;
