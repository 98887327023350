const NftCard = ({ nft, setModalNFT, setShowModal }) => {

    const showModal = () => {
        setModalNFT(nft)
        setShowModal(true)
        document.body.style.overflow = 'hidden'
    }

    return (
        <div className="nft" onClick={showModal}>
            <div className="nft-img-container">
                <img className='nft-img' key={nft.tokenId} src={nft.image.cachedUrl} alt={nft.description}></img>
            </div>
            <p>{nft.name}</p>
        </div>
    )
}

export default NftCard