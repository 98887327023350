const contract_addresses = {
    "svs": "0x219B8aB790dECC32444a6600971c7C3718252539",
    "sbs": "0xeE0BA89699A3dd0f08CB516C069D81a762f65E56",
    "svs2": "0xEA745B608b2B61987785e87279Db586F328EB8Fc",
    "svsd": "0x66072Ec8d05DE3AFf0a016a0C7522b03916df6ae",
    "champ": "0x685B7705daDf92212c2910e41159d15aE0E10a75",
    "pvs": "0x4799D13714b21380214620971202D0027E7Ec229"
    // "pvs2": "0xCB93927E08Cb4be0eF62B1bd867c5EDBdea1b9ab"
}

export { contract_addresses }