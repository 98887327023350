import React, { useState, useEffect } from 'react';

import NftCard from './NftCard'
import NftModal from './NftModal'
import { fetchNFTs } from '../../utils/fetchNFTs';
import '../../styles/Hodls.css';
import { contract_addresses } from '../../artifacts/contracts';

const HODLs = () => {

  const [NFTs, setNFTs] = useState("")
  const [filteredNFTs, setFilteredNFTs] = useState("")
  const [modalNFT, setModalNFT] = useState("")
  const [showModal, setShowModal] = useState(false)

  const loadData = () => {
    fetchNFTs("0x0029b3D3f83896A20ecDC141052B4f8D19C867c1", setNFTs, setFilteredNFTs, contract_addresses)
    setFilteredNFTs(NFTs)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    let title = 'ImNotBot - HODLs'
    let description = 'A curated assortment of digital assets collected by ImNotBot from the Sneaky Vampire Syndicate (SVS) collections.'
    let url = "https://lair.imnotbot.com/Hodls"
    document.title = title
    document.querySelector('meta[property="og:title"]').setAttribute('content', title)
    document.querySelector('meta[name="description"]').setAttribute('content', description)
    document.querySelector('meta[property="og:description"]').setAttribute('content', description)
    document.querySelector('meta[property="og:url"]').setAttribute('content', url)
    loadData()
  }, [])

  const updateActiveButton = (e) => {
    const buttons = document.getElementsByClassName('nft-filters')[0].getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('active');
    }
    e.target.classList.add('active');
  }

  const resetHodls = (e) => {
    updateActiveButton(e)
    setFilteredNFTs(NFTs)
  }

  const filterHodls = (e, contract) => {
    updateActiveButton(e)
    setFilteredNFTs(NFTs.filter(NFT => NFT.contract.address === contract))
  }

  return (
    <>
    {
      modalNFT && showModal ? <NftModal NFT={modalNFT} showModal={showModal} setShowModal={setShowModal} /> : null
    }
    <section id='Hodls'>
      <div className='hodls-container'>
        <h2>HODLs</h2>
        <div className='nft-filters-container'>
          <div className='nft-filters'>
            <button className='active' onClick={e => resetHodls(e)}>All</button>
            <button onClick={e => filterHodls(e, contract_addresses.svs)}>SVS</button>
            <button onClick={e => filterHodls(e, contract_addresses.sbs)}>SBS</button>
            <button onClick={e => filterHodls(e, contract_addresses.svs2)}>SVS2</button>
            <button onClick={e => filterHodls(e, contract_addresses.champ)}>CHAMP</button>
            <button onClick={e => filterHodls(e, contract_addresses.pvs)}>PVS</button>
          </div>
        </div>
        <div className='nfts'>
          {
            filteredNFTs ? filteredNFTs.map(NFT => {

              // console.log(NFT);

              return (
                <NftCard key={NFT.tokenId + NFT.contract.address} nft={NFT} setModalNFT={setModalNFT} setShowModal={setShowModal}></NftCard>
              )
            }) : <div>No NFTs found</div>
          }
        </div>
      </div>
    </section>
    </>
  )

}

export default HODLs;
