const fetchEthGas = async (setEthGas) => {
    const options = {
        method: 'POST',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        body: JSON.stringify({id: 1, jsonrpc: '2.0', method: 'eth_gasPrice'})
    };
    
    let data;
    try {
        data = await fetch(`https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`, options).then(data => data.json())
        // console.log(data)
        setEthGas(Math.floor(parseInt(data.result, 16) / 1000000000))
    } catch (e) {
        console.log("[fetchEthGas] [ERROR] unable to fetch eth gas")
        setEthGas(null)
    }
}

export { fetchEthGas }