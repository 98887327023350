const InfoModal = ({ showModal, setShowModal }) => {

    function hideModal() {
        setShowModal(false)
        document.body.style.overflow = 'auto'
    }

    return (
        <div id="modal" style={showModal ? {visibility: "visible"} : {visibility: "none"}} onClick={hideModal}>
            <div className='info-modal'>
                <div className='info-header'>
                    <h3 className='game-id'>Coin Flip</h3>
                    <div id='close-game-modal' onClick={hideModal}>&#x2715;</div>
                </div>
                <div className='info-container'>
                    <h4>What is this?</h4>
                    <p><b>Coin Flip</b> is a <b>RNG</b> game that allows players to bet a specified amount of <b>pblood</b> and win based off of the outcome of a coin flip. In this particular coin flip game, the coins have been substituted for moons to have vampire lore.</p>
                    <div className='buttons'>
                        <span className='blood-moon'>Blood Moon</span>
                        <span className='moon'>Full Moon</span>
                    </div>
                </div>
                <div className='info-container'>
                    <h4>How to play?</h4>
                    <p>In order to play <b>Coin Flip</b>, a user must connect their <b>web3 wallet</b> and deposit <b>pblood</b> from the <b>MATIC</b> network.</p>
                    <p>Once a user has deposited their <b>pblood</b> into the game wallet they can <b>host</b> a game or <b>join</b> a game that has been created.</p>
                    <p>The <b>host</b> has the ability to choose which <b>moon</b> they believe the <b>RNG</b> will choose and the <b>joining</b> player will automatically choose the opposite <b>moon</b>.</p>
                    <p>When two players have joined a game, the game will automatically process a <b>RNG</b> coin flip where a winner will be determined.</p>
                    <p>The winner will get the winnings put into their game account where they can withdraw from the game back into their <b>web3 wallet</b> or use the winnings to <b>host</b> or <b>join</b> another game.</p>
                </div>
                <div className='info-container'>
                    <h4>Host</h4>
                    <p>When a user <b>hosts</b> a game they are commiting to provide a specified amount of <b>pblood</b> that will be used during the coin flip.</p>
                    <p>A user will not be able to host a game if the amount of <b>pblood</b> they are wanting to use is not deposited in the game wallet for their <b>web3 wallet</b> address.</p>
                </div>
                <div className='info-container'>
                    <h4>Join</h4>
                    <p>When a user <b>joins</b> a game they are commiting to provide the specified amount that the host of the game has set. The amount of <b>pblood</b> that will be used during the coin flip is shown in the game footer.</p>
                    <p>A user will not be able to join a game if they do n ot have the needed amount of <b>pblood</b> deposited in the game wallet for their <b>web3 wallet</b> address.</p>
                </div>
                <div className='info-container'>
                    <h4>Close</h4>
                    <p>A <b>host</b> has the ability to <b>close</b> a game if they want. However, the <b>host</b> is not able to close a game once a second player joins the game.</p>
                    <p>When a <b>host</b> closes a game, their <b>pblood</b> will be returned to their game account.</p>
                </div>
                <div className='info-container'>
                    <h4>Flip</h4>
                    <p>The coin flip happens with a <b>RNG</b> that comes from <b><a href='https://chain.link'>chainlink</a></b> so that the flip is fair for all parties.</p>
                    <p><b>Each game has a house fee of 5% that is taken from the winnings at the end.</b> There are no fees for a closed game.</p>
                    <p>The fees from each game are what help pay for the fees associated with the <b><a href='https://chain.link/vrf'>chainlink RNG</a></b>.</p>
                </div>
                <div className='info-container'>
                    <h4>pblood & MetaMask</h4>
                    <p><b>pblood</b> is the <b>ERC20</b> token that was made for the <b>SVS</b> ecosystem and can be seen on <b><a href='https://polygonscan.com/token/0x9c8919d6e97be2b8b823fffb699ef1cb3422c77f?a=0x0029b3D3f83896A20ecDC141052B4f8D19C867c1'>polygonscan</a></b>.</p>
                    <p>MetaMask does not come with the polygon network so it must be added in order to use the utility on this site. In order to add the polygon network to MetaMask you must go to <b><a href='https://polygonscan.com/'>polygonscan.com</a></b> and add the network to metamask with a button in the footer of the page.</p>
                </div>
            </div>
        </div>
    )
}

export default InfoModal