const { ethers } = require("ethers");
const svsd_abi = require("../artifacts/contracts/svsd.json")
const { contract_addresses } = require('../artifacts/contracts')
const { fetchNFT } = require('./fetchNFTs')

const fetchVamp = async (vamp_id, setVampiress, setBat, setVamp, setFetchError) => {
    try {
        // Fetch vamp metadata
        const vamp = await fetchNFT(vamp_id, contract_addresses.svs)
        if (vamp) {
            setVamp(vamp)
        } else {
            setVamp(null)
            console.error(`[fetchVamp] [ERROR] No svs with id: ${vamp_id}`)
            setFetchError(`Error fetching Vampiress ${vamp_id}`)
            return
        }
        // Fetch if vamp has a bat companion
        // NOTE: using vmap_id because bat companion will have the same id
        const bat = await fetchNFT(vamp_id, contract_addresses.sbs)
        if (bat) {
            // console.log(bat)
            setBat(bat)
            console.log(`[fetchVamp] bat received with vamp ${vamp_id}:${vamp_id}`)
            setFetchError(`Vamp ${vamp_id} has companion Bat ${vamp_id}`)
        } else {
            setBat(null)
            console.log(`[fetchVamp] No bat received for vamp ${vamp_id}`)
            setFetchError(`No bat companion for Vamp ${vamp_id}`)
        }
    } catch(e) {
        setVamp(null)
        setBat(null)
        setVampiress(null)
        console.error(`[fetchVamp] [ERROR] No svs with id: ${vamp_id} ${e}`)
        setFetchError(`Error fetching Vamp ${vamp_id}`)
    }
}

const fetchVampiress = async (vampiress_id, setVampiress, setBat, setVamp, setFetchError) => {
    const provider = new ethers.providers.AlchemyProvider('homestead', process.env.REACT_APP_ALCHEMY_API_KEY);
    const svsd_contract = new ethers.Contract(contract_addresses.svsd, svsd_abi.abi, provider);
    try {
        // Fetch vampiress metadata
        const vampiress = await fetchNFT(vampiress_id, contract_addresses.svs2)
        if (vampiress) {
            setVampiress(vampiress)
        } else {
            setVampiress(null)
            console.error(`[fetchVampiress] [ERROR] No svs2 with id: ${vampiress_id}`)
            setFetchError(`Error fetching Vampiress ${vampiress_id}`)
            return
        }
        // Fetch if vampiress devoured a bat
        const bat_id_bigNum = await svsd_contract.vampiressesHaveDevoured([vampiress_id])
        // console.log("bigNum:",bat_id_bigNum)
        const bat_id = ethers.utils.formatUnits(bat_id_bigNum[0],0)
        // Determine if bat data is valid
        if (bat_id_bigNum > 0) {
            // Fetch bat metadata
            const bat = await fetchNFT(bat_id, contract_addresses.sbs)
            // console.log(bat)
            setBat(bat)
            console.log(`[fetchVampiress] bat received with vampiress ${vampiress_id}:${bat_id}`)
            setFetchError(`Vampiress ${vampiress_id} has devoured Bat ${bat_id}`)
        } else {
            setBat(null)
            console.log(`[fetchVampiress] No bat received with vampiress ${vampiress_id}`)
            setFetchError(`No bat devoured by Vampiress ${vampiress_id}`)
        }
        // Determine if vampiress has perfect pair vamp
        // console.log(vampiress)
        if (vampiress.raw.metadata.attributes[12].value !== 0) {
            // Fetch vamp metadata
            const vamp_id = vampiress.raw.metadata.attributes[12].value
            const vamp = await fetchNFT(vamp_id, contract_addresses.svs)
            // console.log(vamp)
            setVamp(vamp)
            console.log(`[fetchVampiress] vamp received with vampiress ${vampiress_id}:${vamp_id}`)
            setFetchError(`Vampiress ${vampiress_id} has perfect pair Vamp ${vamp_id}`)
        } else {
            setVamp(null)
            console.log(`[fetchVampiress] No vamp received with vampiress ${vampiress_id}`)
            setFetchError(`No vamp perfect pair by Vampiress ${vampiress_id}`)
        }
    } catch(e) {
        setVamp(null)
        setBat(null)
        setVampiress(null)
        console.error(`[fetchVampiress] [ERROR] No svs2 with id: ${vampiress_id} ${e}`)
        setFetchError(`Error fetching Vampiress ${vampiress_id}`)
    }
}

export { fetchVamp, fetchVampiress }