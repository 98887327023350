import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import '../styles/Footer.css';

function Footer() {
  const websiteName = "ImNotBot";
    return (
      <footer>
        <div className='footer-links'>
          <div className='link_list'>
            <h3>Content</h3>
            <Link to='/hodls'>Hodls</Link>
            <Link to='/data'>Data</Link>
            <Link to='/vamps'>Vamps</Link>
            <Link to='/games'>Games</Link>
          </div>
          <div className='link_list'>
            <h3>Legal</h3>
            <Link to='https://imnotbot.com/termsofuse'>Terms</Link>
            <Link to='https://imnotbot.com/privacypolicy'>Privacy</Link>
            <Link to='https://imnotbot.com/cookiepolicy'>Cookies</Link>
          </div>
        </div>
        <div>
          <p>© 2023 <b> {websiteName}</b> │ All Rights Reserved.</p>
        </div>
    </footer>
  );
}

export default Footer;
